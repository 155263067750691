import React from 'react'
import WebsiteThumbnail from '../assets/website-thumbnail.png'
import SpotifyPhoto from '../assets/website-spotify.png' 
import WorkoutPhoto from '../assets/website-workout.png' 
import QuotePhoto from '../assets/website-quote.png' 
import { Title, Subtitle, Subsubtitle, Body, Item, Image } from '../components/Common'


export const WebsitePage = () => {
    let spotifyDescription = "The component is created using the embbed feature provided by Spotify."  
    let workoutDescription = "The reason behind this architectural choice is to prevent bad actors from exploiting the daily limit of number of request I'm allowed to send to Fitbit's database."
    let quoteDescription = "All of the quotes can be seen using the browser's inspection tool lol."
    return (
    <div className='w-full flex justify-center bg-stone-100 dark:bg-stone-700'>
        <div className='w-full sm:w-2/3 pt-12 sm:pt-16 pb-4 px-8 sm:px-16 dark:bg-stone-600 dark:text-white'>
            
            <Image photo={WebsiteThumbnail} description={""}/>

            <Title>NOTALEPOWER'S WEBSITE</Title>
            <Body>
                Nowadays having a website is becoming essential across all job fields. <br/>
                It provides a unique opportunity to: express yourself, showcase your skills, and leave a lasting impression. <br/>
                This website serves as a personal archive of my growth and achievements over the years. <br/>
            </Body>

            <Subtitle>1 - Architecture </Subtitle>
            <Body>This section will focus on explaining the reasoning behind the architectural choices made in the development of this website.</Body>
            
            <Subsubtitle>1.1 - Front-End </Subsubtitle>
            <Body> React was chosen as the framework for the front-end development due to the following reasons:</Body>
            
            <Item >
                - Excellent Documentation <br/> 
                React provides clear tutorials and explanations of its architecture and components on their website. <br/>
                They also include a web-based sandbox, allowing beginners to apply the concepts they've learned in a hands-on environment, enhancing information retention. 
            </Item>

            <Item >
                - Single Page Application (SPA) <br/>
                From the start, I knew I wanted a SPA website, as it provides fast and smooth user experience by dynamically updating the content on a single page, without requiring full page reloads.
            </Item>

            <Item >
                - Popularity <br/>
                Yeah, I know, I'm such a Hypebeast, but being popular also means there is a vast community supporting it, contributing to the development of fantastic pre-made libraries.
            </Item>
            
            <Subsubtitle>1.2 - Styling </Subsubtitle>
            <Body>
                Tailwind CSS was chosen as the styling framework for its simplicity and fast implementation. <br/>
                While it may not be the top choice for professional front-end developers ... I am NOT a professional front-end developer. So for my purpose, I think it's more than enough.
            </Body>

            <Subsubtitle>1.3 - Back-End </Subsubtitle>
            <Body>
            Cloudflare Pages were used to deliver the static website, while Cloudflare Workers were used to handle API interactions on the back-end to support dynamic content. <br/>
            Although this stack may not be the most common choice among developers, it was selected for its convenience and robust network services provided by Cloudflare.
            </Body>

            <Subtitle> 2 - Content's Layout </Subtitle>
            <Body>
                From the start, I knew wanted to split the website into two sections: one focused on my professional experience (like a CV)
                and another for personal content (like a blog).
            </Body>

            <Subsubtitle>2.1 - Professional's Page Layout </Subsubtitle>
            <Body>
                When visitors land on the website, the first thing they should know is what I look like and what is my academic background.
                From there, my skills are showcased, highlighting the areas in which I specialize. 
                Finally, a Project Area is displayed, featuring all the projects I've worked on, along with a brief description and a link to explore the details.
            </Body>

            <Subsubtitle>2.2 - Personal's Page Layout </Subsubtitle>
            <Body>
                The layout of this page follows a similar structure to the previous one, except for its content. <br/>
                This page is more informal, and features different sections, each with unique information I’d like to share.
                Details about each section and their implementation will be provided in the following chapter.

            <Subtitle> 3 - Section's Implementation </Subtitle>
            The personal page consists of various sections, each showcasing dynamic content. 
            This part will explain how information is retrieved. 

            <Subsubtitle>3.1 - Most listened song </Subsubtitle>
            <Body>
                Everyone enjoys music, and each month, there's often one song that you can't stop listening to. <br/>
                This section showcases my monthly favorite songs. <br/>
                For this feature, the song is manually picked and is NOT based on the statistics in my Spotify account (as I don't have a Premium subscription).
            </Body>
                
            <Image photo={SpotifyPhoto} description={spotifyDescription}/>

            <Subsubtitle>3.2 - Quotes </Subsubtitle>
            <Body> 
                Have you ever heard a quote that got stuck in your head, even years later? This section is dedicated to sharing such quotes. <br/>
                To implement this, quotes were directly embedded into the source code.
                This approach was chosen because: I don't have a large collection of quotes to store and it was quick to implement.
            </Body>

            <Image photo={QuotePhoto} description={quoteDescription}/>

            <Subsubtitle>3.3 - Total calories burned </Subsubtitle>
            <Body>
                The health data collected by smartwatches is kinda useless.
                It doesn't contain valuable information able to infer a person's health or habits (but don't quote me on that).
                It's also not sensitive, so ... why not share it? I mean ... what can a malevolent user do with the amount of calories I've burned?
                Build me a workout plan?! <br/>
                For its implementation a custom API server was deployed, which has two features:
                
                <Item> 1) Every 4 hours, it fetches the data from Fitbit's database using OAuth2 protocol to authenticate. Then the information is stored locally. </Item>
                <Item> 2) When invoked, it reads the most recent value and returns it to the browser. </Item>
            
            </Body>

                <Image photo={WorkoutPhoto} description={workoutDescription}/>

            <Subsubtitle>3.4 - Tweet Generator </Subsubtitle>
            <Body>
                Do you want to know how I normally talk? This section is dedicated for that. <br/>
                For its implementation I trained an AI model on my text conversations (more details on the project TBD).
                Then after training I wrapped the model inside a Flask server and deploy it.
            </Body>

            <Subtitle>4 - Conclusions </Subtitle>
            <Body>Building a website from scratch was challenging, but overall, it was surprisingly simple.</Body>

            <Subsubtitle>4.1 - Takeaways</Subsubtitle>    
                Here are some key skills and knowledge I gained throughout the process:
                
                <Item>- Retrieving dynamic content using APIs.</Item>
                <Item>- Deploying a website.</Item>
                <Item>- Creating a responsive and user-friendly design.</Item>
                <Item>- Implementing support for dark mode.</Item>
                <Item>- Buying and managing a domain.</Item>
            
                <Subsubtitle>4.2 - Future developments</Subsubtitle>
                The webpage could be further improved, but for now, I'm quite satisfied with the results.
                Future improvements will include:
                <Item>- Migration to Next.js.</Item>
                <Item>- New sections on the blog page.</Item>
                <Item>- New pages's projects.</Item>

            </Body>

        </div>
    </div>
  )
}
