const Title = ({children}) =>{
    return(
        <div className='uppercase font-bold text-center text-xl sm:text-4xl mt-4 sm:mt-8'>
            {children}
        </div>
    )
}

const Subtitle = ({children}) =>{
    return(
        <div className='text-lg capitalize sm:text-3xl font-bold mt-6 sm:mt-8'>
            {children}
        </div>
    )
}

const Subsubtitle = ({children}) =>{
    return(
        <div className='text-base uppercase sm:text-2xl mt-4 sm:mt-8'>
            {children}
        </div>
    )
}

const Body = ({children}) =>{
    return(
        <div className='text-sm sm:text-xl sm:mt-1'>
            {children}
        </div>
    )
}

const Item = ({children}) =>{
    return(
        <div className="text-sm sm:text-lg px-4 sm:px-8 pt-2 sm:pt-4">
            {children}
        </div>
    )
}

const Image = (props) =>{
    return(
        <div className='mt-4 sm:mt-8'>
            <img src={props.photo} className='rounded-2xl sm:rounded-3xl object-cover' alt='Foto'/>
            <Description>{props.description}</Description>
        </div>
    )
}

const Description = ({children}) =>{
    return(
        <div className='text-xs sm:text-base text-stone-600 dark:text-stone-100 italic my-1 px-4 text-center'>
        {children}
        </div>
    )
}


export const Common = () => {
  return (
    <div>Common</div>
  )
}

export { Title, Subtitle, Subsubtitle, Body, Item, Image, Description};