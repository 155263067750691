import React from 'react'
import { NavLink } from 'react-router-dom';
import { Title, Subtitle, Subsubtitle, Body, Description } from '../components/Common';

export const ProjectsPage = () => {
  return (
      <div className='w-full min-h-screen flex justify-center bg-stone-100 dark:bg-stone-700'>
        
          <div className='w-full sm:w-2/3 mt-12 sm:mt-16 sm:px-10 dark:bg-stone-600 dark:text-white'>
              
              <div className='mt-8 text-center'>
                <div className='text-sm sm:text-2xl text-center mb-4'>⚠️ This Page is still under development ⚠️</div>
                
                <Subtitle>LIST OF PROJECTS:</Subtitle>

                <Subsubtitle><NavLink to="https://amslaurea.unibo.it/31435/">PatchCore Transformed</NavLink></Subsubtitle>
                <Description>This project was part of my master's thesis. It focused on integrating ViTs into PatchCore as an alternative to traditional CNNs.</Description>

                <Subsubtitle><NavLink to="/website">notalepower's Website</NavLink></Subsubtitle>
                <Description>The webpage that you're currently on is also a personal project of mine, created to help me learn how to build a website from scratch.</Description>

              </div>

          </div>
      </div>
  )
}
